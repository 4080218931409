import { useEffect, useState } from 'react';
import { FormatDateToHuman } from '../../helpers/DateHelpers';
import { Link } from 'react-router-dom';
import { UtcToLocal } from '../../helpers/DateHelpers';
import { Http } from '../../http';
import DeviceWidget from './DeviceWidget';
import EmployeeWidget from './EmployeeWidget';
import GuestWidget from './GuestWidget';
import { KTSVG } from '../../helpers';
import clsx from 'clsx';
import { useGetNotificationsQuery } from '../../app/storeReducers/NotificationsAPI';

const DashboardList = (props) => {
    const { accountId } = props;
    const [summary, setSummary] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const response = await Http.get(
                `api/accounts/${accountId}/summary`
            );
            if (response.result !== undefined) setSummary(response.result);
        }

        loadResourcesAndDataAsync();
    }, [accountId]);

    const { data, refetch } = useGetNotificationsQuery({
        accountId,
        onlyNew: true,
        pageIndex: 1,
        pageSize: 10,
    });

    const SetAllRead = async () => {
        await Http.put('api/notifications/web/read/all');

        refetch();
    };

    return (
        <div className='row g-5 g-xl-8'>
            <div className='col-md-6'>
                <GuestWidget
                    guest_count_today={summary.guest_count_today}
                    guest_count_today_car={summary.guest_count_today_car}
                    accountId={accountId}
                />
                <EmployeeWidget
                    employees_count_total={summary.employees_count_total}
                    employees_count_banned={summary.employees_count_banned}
                    vehicles_count_total={summary.vehicles_count_total}
                    accountId={accountId}
                />
                <DeviceWidget
                    devices_count_total={summary.devices_count_total}
                    devices_count_online={summary.devices_count_online}
                    devices_count_offline={summary.devices_count_offline}
                    accountId={accountId}
                    className='card-xl-stretch mb-xl-8'
                    color='primary'
                />
            </div>
            <div className='col-md-6'>
                <div className='card border'>
                    <div className='d-flex bgi-no-repeat rounded-top justify-content-between border-bottom'>
                        <span className='fw-boldest px-4 my-3 font-size-18'>
                            Уведомления
                        </span>
                        <span
                            className='px-5 my-3 av-btn fw-bold'
                            onClick={SetAllRead}
                        >
                            Отметить прочитанными
                        </span>
                    </div>
                    <div className='scroll-y mt-3 notify-menu-panel'>
                        {data?.result?.map((alert, index) => (
                            <div
                                key={`alert${index}`}
                                className='d-flex flex-stack notify-message mb-3 border-bottom px-4'
                            >
                                <div className='d-flex'>
                                    <div className='symbol symbol-48px me-4'>
                                        <span
                                            className={clsx(
                                                'symbol-label',
                                                `bg-light-${alert.state}`
                                            )}
                                        >
                                            {' '}
                                            <KTSVG
                                                path={`/media/${alert.icon}`}
                                                className={`svg-icon-2 svg-icon-${alert.state}`}
                                            />
                                        </span>
                                    </div>
                                    <div className='mb-0'>
                                        <b>
                                            {alert.subject}
                                            <span className='badge badge-new ms-2'>
                                                Новое
                                            </span>
                                        </b>
                                        <div className='text-gray-600 mb-3'>
                                            {alert.message}
                                        </div>
                                        <div className='notify-date mb-3'>
                                            {FormatDateToHuman(
                                                UtcToLocal(alert.time),
                                                true
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='text-center pb-3'>
                        <Link
                            to='/personal/notifications'
                            className='btn btn-color-gray-600 btn-active-color-primary'
                        >
                            Посмотреть все{' '}
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-5'
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardList;
