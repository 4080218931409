import { useEffect, useState } from 'react';
import { ToUtcString } from '../../../helpers/DateHelpers';
import { Http } from '../../../http';
import FilterListSelected from './FilterListSelected';
import GuestsListSelected from './GuestsListSelected';
import FilterAccountSelector from './FilterAccountSelector';

const subjectTypes = [
    { id: 0, name: '--Не выбрано--' },
    { id: 1, name: 'Сотрудники' },
    { id: 2, name: 'Посетители' },
    { id: 3, name: 'Транспорт' },
];

const events = [
    { id: 1, name: 'in', description: 'Вход' },
    { id: 2, name: 'out', description: 'Выход' },
    { id: 3, name: 'button_in', description: 'Вход по кнопке' },
    { id: 4, name: 'button_out ', description: 'Выход по кнопке' },
    { id: 5, name: 'web_in ', description: 'Вход через сайт' },
    { id: 6, name: 'web_out ', description: 'Выход через сайт' },
    { id: 7, name: 'call_in ', description: 'Вход по звонку' },
    { id: 8, name: 'call_out ', description: 'Выход по звонку' },
    { id: 9, name: 'mobile_in', description: 'Вход через приложение' },
    { id: 10, name: 'mobile_out ', description: 'Выход через приложение' },
    { id: 11, name: 'link_in ', description: 'Вход по ссылке' },
    { id: 12, name: 'link_out ', description: 'Вход по ссылке' },
    { id: 21, name: 'car_in ', description: 'Въезд' },
    { id: 22, name: 'car_out ', description: 'Выезд' },
    { id: 23, name: 'car_button_in ', description: 'Въезд по кнопке' },
    { id: 24, name: 'car_button_out ', description: 'Выезд по кнопке' },
    { id: 25, name: 'car_web_in ', description: 'Въезд через сайт' },
    { id: 26, name: 'car_web_out ', description: 'Выезд через сайт' },
    { id: 27, name: 'car_call_in ', description: 'Въезд по звонку' },
    { id: 28, name: 'car_call_out ', description: 'Выезд по звонку' },
    {
        id: 29,
        name: 'car_mobile_in ',
        description: 'Въезд через приложение',
    },
    {
        id: 30,
        name: 'car_mobile_out ',
        description: 'Выезд через приложение',
    },
    { id: 31, name: 'car_link_in ', description: 'Въезд по ссылке' },
    { id: 32, name: 'car_link_out ', description: 'Выезд по ссылке' },
    {
        id: 33,
        name: 'car_recognition_in ',
        description: 'Въезд по распознаванию',
    },
    {
        id: 34,
        name: 'car_recognition_out ',
        description: 'Выезд по распознаванию',
    },
    { id: 35, name: 'car_guest_order_in ', description: 'Въезд по заявке' },
    {
        id: 36,
        name: 'car_guest_order_out ',
        description: 'Выезд по заявке',
    },
    {
        id: 37,
        name: 'special_car_recognition_in ',
        description: 'Въезд спецтранспорта по распознаванию',
    },
    {
        id: 38,
        name: 'special_car_recognition_out ',
        description: 'Выезд спецтранспорта по распознаванию',
    },
];

const FilterComponent = (props) => {
    const {
        accountId,
        account,
        dateStart,
        dateFinish,
        setDateStart,
        setDateFinish,
        filterCard,
        setFilterCard,
        filterAccount,
        setFilterAccount,
        subjectSelected,
        setSubjectSelected,
        setFilterEmployees,
        setFilterDevices,
        setFilterVehicles,
        setFilterGuests,
        setFilterEvents,
        loadData,
    } = props;

    const [dropdownActionIsOpen, setDropdownActionIsOpen] = useState(false);
    const [selectAccounts, setSelectAccounts] = useState([]);

    const [devices, setDevices] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [clearTrigger, setClearTrigger] = useState(1);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadDevices();
            loadEmployes();
            loadVehicles();

            if (account.parent === null && account.type !== 'basic') {
                const getResult = await Http.get(
                    `api/accounts/${accountId}/subaccounts?sort=name`
                );
                let sAcc = [];
                sAcc.push({ id: 0, name: '--Не выбран--' });
                sAcc.push({ id: account.id, name: account.name });
                getResult.result.map((item) =>
                    sAcc.push({ id: item.id, name: item.name })
                );
                setSelectAccounts(sAcc);
                setFilterAccount(0);
            } else {
                let sAcc = [];
                sAcc.push({ id: 0, name: '--Не выбран--' });
                sAcc.push({ id: account.id, name: account.name });
                setSelectAccounts(sAcc);
                setFilterAccount(0);
            }
        }

        loadResourcesAndDataAsync();
    }, [account]);

    useEffect(() => {
        async function loadData() {
            loadVehicles();
            loadEmployes();
        }

        loadData();
    }, [filterAccount]);

    const loadDevices = async () => {
        const getDevicesResult = await Http.get(
            'api/devices?sort=name&accountId=' + accountId
        );
        setDevices(getDevicesResult.result);
    };

    const loadVehicles = async (search = '') => {
        const getVehiclesResult = await Http.get(
            `api/vehicles?sort=name&accountId=${
                filterAccount > 0 ? filterAccount : accountId
            }&search=${search}`
        );
        setVehicles(getVehiclesResult.result);
    };

    const loadEmployes = async (search = '') => {
        const getEmployeesResult = await Http.get(
            `api/employees?sort=last_name&accountId=${
                filterAccount > 0 ? filterAccount : accountId
            }&search=${search}`
        );
        setEmployees(getEmployeesResult.result);
    };

    let subjectController = null;

    switch (subjectSelected) {
        case '0':
            subjectController = null;
            break;
        case '1':
            subjectController = (
                <div className='form-group mb-3'>
                    <label>Сотрудники</label>
                    <FilterListSelected
                        entity={employees?.map((item) => {
                            return { key: item.id, value: item.name };
                        })}
                        callback={setFilterEmployees}
                        searchCallback={loadEmployes}
                    />
                </div>
            );
            break;
        case '2':
            subjectController = (
                <div className='form-group mb-3'>
                    <label>Посетители</label>
                    <GuestsListSelected
                        accountId={
                            filterAccount > 0 ? filterAccount : accountId
                        }
                        callback={setFilterGuests}
                    />
                </div>
            );
            break;
        case '3':
            subjectController = (
                <div className='form-group mb-3'>
                    <label>Транспорт</label>
                    <FilterListSelected
                        entity={vehicles?.map((item) => {
                            return { key: item.id, value: item.carNumber };
                        })}
                        callback={setFilterVehicles}
                        searchCallback={loadVehicles}
                    />
                </div>
            );
            break;

        default:
            break;
    }

    const clearFilter = () => {
        setDateStart(new Date());
        setDateFinish(new Date());
        setFilterCard('');
        setFilterAccount(selectAccounts[0].id);
        setSubjectSelected(0);
        setFilterEmployees([]);
        setFilterGuests([]);
        setFilterVehicles([]);
        setFilterDevices([]);
        setFilterEvents([]);
        setClearTrigger(clearTrigger + 1);
    };

    return (
        <div className='dropdown me-3'>
            <button
                className='btn btn-sm av-btn-simple dropdown-toggle'
                type='button'
                onClick={() => setDropdownActionIsOpen(!dropdownActionIsOpen)}
            >
                <i className='fas fa-filter' />
            </button>
            <div
                className={
                    'dropdown-menu dropdown-menu-end p-5 border' +
                    (dropdownActionIsOpen ? ' show' : '')
                }
            >
                <div className='form-group'>
                    <label>Период</label>
                    <div
                        className='input-group mb-3'
                        style={{ width: '400px' }}
                    >
                        <input
                            type='date'
                            className='form-control'
                            value={ToUtcString(dateStart).split('T')[0]}
                            onChange={(e) =>
                                setDateStart(new Date(e.target.value))
                            }
                        />
                        <span className='input-group-text'>-</span>
                        <input
                            type='date'
                            className='form-control'
                            value={ToUtcString(dateFinish).split('T')[0]}
                            onChange={(e) =>
                                setDateFinish(new Date(e.target.value))
                            }
                        />
                    </div>
                </div>

                <div className='form-group mb-3'>
                    <label>Номер карты доступа</label>
                    <input
                        type='text'
                        className='form-control'
                        value={filterCard}
                        onChange={(e) => setFilterCard(e.target.value)}
                    />
                </div>
                <div className='form-group mb-3'>
                    <label>Аккаунт</label>
                    <FilterAccountSelector
                        callback={(value) => setFilterAccount(value)}
                        clearTrigger={clearTrigger}
                    />
                </div>
                <div className='form-group mb-3'>
                    <label>Субъект</label>
                    <select
                        className='form-select'
                        value={subjectSelected}
                        onChange={(e) => setSubjectSelected(e.target.value)}
                    >
                        {subjectTypes?.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
                {subjectController}
                <div className='form-group mb-3'>
                    <label>Устройства</label>
                    <FilterListSelected
                        entity={devices?.map((item) => {
                            return {
                                key: item.id,
                                value: item.uid + ' ' + item.name,
                            };
                        })}
                        callback={setFilterDevices}
                        clearTrigger={clearTrigger}
                    />
                </div>
                <div className='form-group mb-3'>
                    <label>События</label>
                    <FilterListSelected
                        entity={events?.map((item) => {
                            return {
                                key: item.name,
                                value: item.description,
                            };
                        })}
                        callback={setFilterEvents}
                        clearTrigger={clearTrigger}
                    />
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='d-grid gap-2'>
                            <button
                                className='btn av-btn-simple btn-sm'
                                onClick={clearFilter}
                            >
                                Сбросить
                            </button>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='d-grid gap-2'>
                            <button
                                className='btn av-btn-primary btn-sm'
                                onClick={() => [
                                    loadData(),
                                    setDropdownActionIsOpen(false),
                                ]}
                            >
                                Применить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterComponent;
