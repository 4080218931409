const DeviceWidget = (props) => {
    const { devices_count_total, devices_count_online, devices_count_offline } =
        props;

    return (
        <div className='card border widget-card mb-6'>
            <div className='card-body p-6'>
                <div className='widget-title mb-3'>
                    <img
                        className='img-fluid me-4'
                        src='/media/icons/avanguard/widgetDeviceIcon.png'
                    />
                    Устройства
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>
                        {devices_count_total}
                    </div>
                    <div className='widget-count-title'>Всего</div>
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>
                        {devices_count_online}
                    </div>
                    <div className='widget-count-title'>В сети</div>
                </div>
                <div className='widget-count-block d-inline-block me-5 text-danger'>
                    <div className='widget-count-number'>
                        {devices_count_offline}
                    </div>
                    <div className='widget-count-title'>Не в сети</div>
                </div>
            </div>
        </div>
    );
};

export default DeviceWidget;
