import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.css';
import LayoutMain from './components/LayoutMain';
import { MasterLayout } from './components/layout/MasterLayout';

import LoginPage from './pages/LoginPage';
import LogPage from './pages/LogPage';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCurrentUserAsync,
    selectIsAuthorize,
    setIsAuthorize,
} from './app/storeReducers/CurrentUserSlice';
import RegistrationPage from './pages/RegistrationPage';
import LogoutPage from './pages/LogoutPage';
import ProfilePage from './pages/personal/profile/ProfilePage';
import NotificationsPanel from './components/notification/NotificationsPanel';
import PrivateRoute from './components/PrivateRoute';
import EmployeesPage from './pages/employee/EmployeesPage';
import GroupsPage from './pages/employeeGroups/EmployeeGroupsPage';
import RolesPage from './pages/employeeRoles/RolesPage';
import DevicesPage from './pages/devices/DevicesPage';
import SchemesPage from './pages/schemes/SchemesPage';
import ParkingAreasPage from './pages/parkingAreas/ParkingAreasPage';
import VehiclesPage from './pages/vehicles/VehiclesPage';
import TariffsPage from './pages/tariffs/TariffsPage';
import VehicleEditPage from './pages/vehicles/VehicleEditPage';
import ServicesPage from './pages/services/ServicesPage';
import { default as AccountSchedulesPage } from './pages/schemeSchedules/SchedulesPage';
import GuestsPage from './pages/guests/GuestsPage';
import EmployeeCardPage from './pages/employee/employeeCard/EmployeeCardPage';
import { LayoutProvider } from './_metronic/layout/core';
import { PageTitle } from './components/layout/core/PageData';
import DeviceCardPage from './pages/devices/DeviceCardPage';
import EventsPage from './pages/admin/events/EventsPage';
import OperatorGusetsEntrancePage from './pages/operator/guests/Entrance/OperatorGuestsEntrancePage';
import GuestCardPage from './pages/guests/GuestCardPage';
import SchemeCardPage from './pages/schemes/schemeCard/SchemeCardPage';
import GroupCardPage from './pages/employeeGroups/groupCard/GroupCardPage';
import AccesslogPage from './pages/accesslog/AccesslogPage';
import UserlogPage from './pages/userlog/UserlogPage';
import ParkingCardPage from './pages/parkingAreas/parkingAreaCard/ParkingCardPage';
import RoleCardPage from './pages/employeeRoles/roleCard/RoleCardPage';
import { default as AccountScheduleCardPage } from './pages/schemeSchedules/scheduleCard/ScheduleCardPage';
import TariffCardPage from './pages/tariffs/tariffCard/TariffCardPage';
import OperatorGusetsParkingPage from './pages/operator/guests/parking/OperatorGuestsParkingPage';
import SubaccountsPage from './pages/subaccounts/SubaccountsPage';
import AccountsPage from './pages/admin/accounts/AccountsPage';
import AccountCardPage from './pages/admin/accounts/accountCard/AccountCardPage';
import HostsPage from './pages/admin/hosts/HostsPage';
import HostCardPage from './pages/admin/hosts/hostCard/HostCardPage';
import Error403 from './pages/errors/403';
import SupportPage from './pages/support/SupportPage';
import SupportCardPage from './pages/support/supportCard/SupportCardPage';
import CamerasPage from './pages/cameras/CamerasPage';
import CameraCardPage from './pages/cameras/cameraCard/CameraCardPage';
import AdminDevicesPage from './pages/admin/devices/AdminDevicesPage';
import AdminEmployeesPage from './pages/admin/employees/AdminEmployeesPage';
import AdminCardsPage from './pages/admin/cards/AdminCardsPage';
import ReportParkingPage from './pages/reports/parking/ReportParkingPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import ParkingPlacesPage from './pages/parkingAreas/parkingAreaCard/places/ParkingPlacesPage';
import PlaceCardPage from './pages/parkingAreas/parkingAreaCard/places/placeCard/PlaceCardPage';
import MonitorViewPage from './pages/monitor/View/MonitorViewPage';
import MonitorsPage from './pages/monitor/MonitorsPage';
import MonitorCardPage from './pages/monitor/Card/MonitorCardPage';
import AccountNotificationSettingsPage from './pages/notifications/AccountNotificationSettingsPage';
import PersonalNotificationSettingsPage from './pages/notifications/PersonalNotificationSettingsPage';
import PersonalNotificationsListPage from './pages/notifications/PersonalNotificationsListPage';
import PersonalAccountsPage from './pages/personal/accounts/PersonalAccountsPage';
import DemoPage from './pages/DemoPage';
import NightWatchPage from './pages/operator/guests/parking/NightWatchPage';
import LocationsPage from './pages/locations/LocationsPage';
import LocationCardPage from './pages/locations/locationCard/LocationCardPage';
import TimeTrackingsPage from './pages/reports/timeTrackings/TimeTrackingsPage';
import ThermalPrinter from './pages/guests/ThermalPrinter';
import SecutiryContext from './features/securityContext';
import AdminDashboardPage from './pages/admin/dashboard/DashboardPage';
import SchedulesPage from './pages/schedules/SchedulesPage';
import ScheduleCardPage from './pages/schedules/card/ScheduleCardPage';
import { Http } from './http';
import ParkingBanPage from './pages/admin/parkingBan/ParkingBanPage';
import RequestFormPage from './pages/requestForm/RequestFormPage';
import RequestRegisterPage from './pages/admin/requestRegister/RequestRegisterPage';
import TimesheetPage from './pages/reports/timesheet/TimesheetPage';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useGetNotificationsQuery } from './app/storeReducers/NotificationsAPI';
import { setNotifications } from './app/storeReducers/HeaderNotificationsSlice';

const App = () => {
    const dispatch = useDispatch();

    const isAuthorize = useSelector(selectIsAuthorize);
    const { data, refetch } = useGetNotificationsQuery({
        accountId: 0,
        onlyNew: true,
        pageIndex: 1,
        pageSize: 10,
    });
    //console.log(process.env.NODE_ENV);
    useEffect(async () => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken !== undefined && accessToken !== null) {
            dispatch(getCurrentUserAsync());
            dispatch(setIsAuthorize());
        }
    });

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (isAuthorize) {
                setContext((await Http.post('api/auth/security', true)).result);
            }
        }

        loadResourcesAndDataAsync();
    }, [isAuthorize]);

    const [context, setContext] = useState(null);

    const [connection, setConnection] = useState(null);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (context) {
                const newConnection = new HubConnectionBuilder()
                    .withUrl(
                        `${process.env.REACT_APP_PLATFORM_URL}ws/notifications`
                    )
                    .withAutomaticReconnect()
                    .build();

                setConnection(newConnection);
                refetch();
            }
        }

        loadResourcesAndDataAsync();
    }, [context]);

    useEffect(() => {
        if (connection && context?.user?.login) {
            connection
                .start()
                .then(() => {
                    connection
                        .invoke('JoinGroup', `user_${context.user.login}`)
                        .catch((err) => {
                            console.log(err);
                        });

                    connection.on('ReceiveMessage', (message) => {
                        refetch();
                    });
                })
                .catch((e) => console.log('Connection failed: ', e));
        }
    }, [connection]);
    useEffect(() => {
        dispatch(setNotifications(data));
        console.log('setData', data);
    }, [data]);

    return (
        <SecutiryContext.Provider value={{ context, setContext }}>
            <LayoutProvider>
                <Router>
                    <Route exact path='/'>
                        <Redirect to='/personal/overview' />
                    </Route>
                    <Route
                        exact
                        path='/demo'
                        render={(props) => <DemoPage {...props} />}
                    />
                    <Route
                        exact
                        path='/thermal'
                        render={(props) => <ThermalPrinter {...props} />}
                    />

                    <Route
                        exact
                        path='/login'
                        render={(props) => <LoginPage {...props} />}
                    />
                    <Route
                        exact
                        path='/logs'
                        render={(props) => <LogPage {...props} />}
                    />
                    <Route
                        exact
                        path='/registration'
                        render={(props) => <RegistrationPage {...props} />}
                    />
                    <Route
                        exact
                        path='/forgotPassword'
                        render={(props) => (
                            <RegistrationPage isForgot {...props} />
                        )}
                    />
                    <Route path='/logout' component={LogoutPage} />
                    <Route
                        path='/errors/403'
                        render={(props) => (
                            <LayoutMain>
                                <Error403 />
                            </LayoutMain>
                        )}
                    />
                    <Route
                        exact
                        path='/requestForm'
                        render={(props) => <RequestFormPage {...props} />}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/dashboard'
                        render={(props) => (
                            <MasterLayout>
                                <DashboardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/support'
                        render={(props) => (
                            <MasterLayout>
                                <SupportPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/support/:ticketId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/support`}
                                ></PageTitle>
                                <SupportCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/personal/profile'
                        render={(props) => (
                            <MasterLayout skipMenu={true} mode={'user'}>
                                <PageTitle>Профиль</PageTitle>
                                <ProfilePage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/personal/overview'
                        render={(props) => (
                            <MasterLayout
                                skipMenu={true}
                                mode={'user'}
                                noToolbar
                            >
                                <PageTitle>Обзор</PageTitle>
                                <PersonalAccountsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/employees'
                        render={(props) => (
                            <MasterLayout>
                                <EmployeesPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/employees/:employeeId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/employees`}
                                ></PageTitle>
                                <EmployeeCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/admin/groups'
                        render={(props) => (
                            <MasterLayout>
                                <GroupsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/admin/groups/:groupId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/admin/groups`}
                                ></PageTitle>
                                <GroupCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/admin/roles'
                        render={(props) => (
                            <MasterLayout>
                                <RolesPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/admin/roles/:roleId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/admin/roles`}
                                ></PageTitle>
                                <RoleCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/devices'
                        render={(props) => (
                            <MasterLayout>
                                <DevicesPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/devices/:deviceId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/devices`}
                                ></PageTitle>
                                <DeviceCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/devices/:deviceId/source_log'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle>События</PageTitle>
                                <EventsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/admin/schemes'
                        render={(props) => (
                            <MasterLayout>
                                <SchemesPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/admin/schemes/:schemeId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/admin/schemes`}
                                ></PageTitle>
                                <SchemeCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/parking'
                        render={(props) => (
                            <MasterLayout noToolbar>
                                <ParkingAreasPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/parkingTariffs'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    breadcrumbs={[
                                        {
                                            title: 'Парковка',
                                            path: `/account/${props.match.params.accountId}/parking`,
                                            isSeparator: false,
                                            isActive: false,
                                        },
                                        {
                                            title: '',
                                            path: '',
                                            isSeparator: true,
                                            isActive: false,
                                        },
                                    ]}
                                    noTitle
                                >
                                    Тарифы
                                </PageTitle>
                                <TariffsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/parking/:parkingId/places'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    breadcrumbs={[
                                        {
                                            title: 'Парковка',
                                            path: `/account/${props.match.params.accountId}/parking`,
                                            isSeparator: false,
                                            isActive: false,
                                        },
                                        {
                                            title: '',
                                            path: '',
                                            isSeparator: true,
                                            isActive: false,
                                        },
                                        {
                                            title: 'Карточка парковки',
                                            path: `/account/${props.match.params.accountId}/parking/${props.match.params.parkingId}`,
                                            isSeparator: false,
                                            isActive: false,
                                        },
                                        {
                                            title: '',
                                            path: '',
                                            isSeparator: true,
                                            isActive: false,
                                        },
                                    ]}
                                    noTitle
                                >
                                    Парковочные места
                                </PageTitle>
                                <ParkingPlacesPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/parking/:parkingId/places/:placeId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/parking/${props.match.params.parkingId}/places`}
                                    breadcrumbs={[
                                        {
                                            title: 'Парковка',
                                            path: `/account/${props.match.params.accountId}/parking`,
                                            isSeparator: false,
                                            isActive: false,
                                        },
                                        {
                                            title: '',
                                            path: '',
                                            isSeparator: true,
                                            isActive: false,
                                        },
                                        {
                                            title: 'Карточка парковки',
                                            path: `/account/${props.match.params.accountId}/parking/${props.match.params.parkingId}`,
                                            isSeparator: false,
                                            isActive: false,
                                        },
                                        {
                                            title: '',
                                            path: '',
                                            isSeparator: true,
                                            isActive: false,
                                        },
                                        {
                                            title: 'Парковочные места',
                                            path: `/account/${props.match.params.accountId}/parking/${props.match.params.parkingId}/places`,
                                            isSeparator: false,
                                            isActive: false,
                                        },
                                        {
                                            title: '',
                                            path: '',
                                            isSeparator: true,
                                            isActive: false,
                                        },
                                    ]}
                                    noTitle
                                >
                                    Карточка парковочного места
                                </PageTitle>
                                <PlaceCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/parkingTariffs/:tariffId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/parkingTariffs`}
                                ></PageTitle>
                                <TariffCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/parkingServices'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    breadcrumbs={[
                                        {
                                            title: 'Парковка',
                                            path: `/account/${props.match.params.accountId}/parking`,
                                            isSeparator: false,
                                            isActive: false,
                                        },
                                        {
                                            title: '',
                                            path: '',
                                            isSeparator: true,
                                            isActive: false,
                                        },
                                    ]}
                                    noTitle
                                >
                                    Услуги
                                </PageTitle>
                                <ServicesPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/parkingReport'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    breadcrumbs={[
                                        {
                                            title: 'Парковка',
                                            path: `/account/${props.match.params.accountId}/parking`,
                                            isSeparator: false,
                                            isActive: false,
                                        },
                                        {
                                            title: '',
                                            path: '',
                                            isSeparator: true,
                                            isActive: false,
                                        },
                                    ]}
                                    noTitle={true}
                                >
                                    Отчёт по парковке
                                </PageTitle>
                                <ReportParkingPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/parking/:parkingId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/parking`}
                                />
                                <ParkingCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/vehicles'
                        render={(props) => (
                            <MasterLayout>
                                <VehiclesPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/vehicles/:vehicleId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/vehicles`}
                                ></PageTitle>
                                <VehicleEditPage {...props} />
                            </MasterLayout>
                        )}
                    />

                    <PrivateRoute
                        exact
                        path='/account/:accountId/admin/schemeSchedules'
                        render={(props) => (
                            <MasterLayout>
                                <AccountSchedulesPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/admin/schemeSchedules/:scheduleId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/admin/schemeSchedules`}
                                ></PageTitle>
                                <AccountScheduleCardPage {...props} />
                            </MasterLayout>
                        )}
                    />

                    <PrivateRoute
                        exact
                        path='/account/:accountId/admin/schedules'
                        render={(props) => (
                            <MasterLayout>
                                <SchedulesPage {...props} />
                            </MasterLayout>
                        )}
                    />

                    <PrivateRoute
                        path='/account/:accountId/admin/schedules/:scheduleId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/admin/schedules`}
                                ></PageTitle>
                                <ScheduleCardPage {...props} />
                            </MasterLayout>
                        )}
                    />

                    <PrivateRoute
                        exact
                        path='/account/:accountId/guests'
                        render={(props) => (
                            <MasterLayout>
                                <GuestsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/guests/:guestId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/guests`}
                                ></PageTitle>
                                <GuestCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/operatorGuestsEntrance'
                        render={(props) => (
                            <MasterLayout>
                                <OperatorGusetsEntrancePage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/operatorGuestsParking'
                        render={(props) => (
                            <MasterLayout>
                                <OperatorGusetsParkingPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/operatorNightWatch'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/operatorGuestsParking`}
                                />
                                <NightWatchPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/accesslogs'
                        render={(props) => (
                            <MasterLayout>
                                <AccesslogPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/admin/userlogs'
                        render={(props) => (
                            <MasterLayout>
                                <UserlogPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/subaccounts'
                        render={(props) => (
                            <MasterLayout>
                                <SubaccountsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/admin/settings'
                        render={(props) => (
                            <MasterLayout>
                                <AccountCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/admin/cards'
                        render={(props) => (
                            <MasterLayout>
                                <AdminCardsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/admin/dashboard'
                        render={(props) => (
                            <MasterLayout mode={'admin'}>
                                <AdminDashboardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/admin/accounts'
                        render={(props) => (
                            <MasterLayout mode={'admin'}>
                                <AccountsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/admin/accounts/:accountId'
                        render={(props) => (
                            <MasterLayout mode={'admin'}>
                                <PageTitle
                                    returnLink={`/admin/accounts`}
                                ></PageTitle>
                                <AccountCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/admin/hosts'
                        render={(props) => (
                            <MasterLayout mode={'admin'}>
                                <HostsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/admin/hosts/:hostId'
                        render={(props) => (
                            <MasterLayout mode={'admin'}>
                                <PageTitle
                                    returnLink={`/admin/hosts`}
                                ></PageTitle>
                                <HostCardPage {...props} />
                            </MasterLayout>
                        )}
                    />

                    <PrivateRoute
                        exact
                        path='/admin/devices'
                        render={(props) => (
                            <MasterLayout mode={'admin'}>
                                <AdminDevicesPage {...props} />
                            </MasterLayout>
                        )}
                    />

                    <PrivateRoute
                        exact
                        path='/admin/employees'
                        render={(props) => (
                            <MasterLayout mode={'admin'}>
                                <AdminEmployeesPage {...props} />
                            </MasterLayout>
                        )}
                    />

                    <PrivateRoute
                        exact
                        path='/admin/cards'
                        render={(props) => (
                            <MasterLayout mode={'admin'}>
                                <AdminCardsPage {...props} />
                            </MasterLayout>
                        )}
                    />

                    <PrivateRoute
                        exact
                        path='/admin/support'
                        render={(props) => (
                            <MasterLayout mode={'admin'}>
                                <SupportPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/admin/support/:ticketId'
                        render={(props) => (
                            <MasterLayout mode={'admin'}>
                                <PageTitle
                                    returnLink={`/admin/support`}
                                ></PageTitle>
                                <SupportCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/admin/locations'
                        render={(props) => (
                            <MasterLayout>
                                <LocationsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/admin/locations/:locationId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/admin/locations`}
                                ></PageTitle>
                                <LocationCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/admin/cameras'
                        render={(props) => (
                            <MasterLayout>
                                <CamerasPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/admin/cameras/:cameraId'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/admin/cameras`}
                                ></PageTitle>
                                <CameraCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/monitors'
                        render={(props) => (
                            <MasterLayout>
                                <MonitorsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/monitors/:monitorId/panel'
                        render={(props) => (
                            <LayoutMain>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/monitors`}
                                ></PageTitle>
                                <MonitorViewPage {...props} />
                            </LayoutMain>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/monitors/:monitorId/card'
                        render={(props) => (
                            <MasterLayout>
                                <PageTitle
                                    returnLink={`/account/${props.match.params.accountId}/monitors`}
                                ></PageTitle>
                                <MonitorCardPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/admin/notify'
                        render={(props) => (
                            <MasterLayout>
                                <AccountNotificationSettingsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/reports/timeTrackings'
                        render={(props) => (
                            <MasterLayout>
                                <TimeTrackingsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/account/:accountId/reports/timesheet'
                        render={(props) => (
                            <MasterLayout>
                                <TimesheetPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        exact
                        path='/account/:accountId/admin/blacklist'
                        render={(props) => (
                            <MasterLayout>
                                <ParkingBanPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    {process.env.NODE_ENV === 'development' ? (
                        <>
                            <PrivateRoute
                                exact
                                path='/account/:accountId/admin/requestRegister'
                                render={(props) => (
                                    <MasterLayout>
                                        <RequestRegisterPage {...props} />
                                    </MasterLayout>
                                )}
                            />
                        </>
                    ) : null}

                    <PrivateRoute
                        exact
                        path='/personal/notifications'
                        render={(props) => (
                            <MasterLayout mode={'user'}>
                                <PersonalNotificationsListPage />
                            </MasterLayout>
                        )}
                    />
                    <PrivateRoute
                        path='/personal/notifications/settings'
                        render={(props) => (
                            <MasterLayout mode={'user'}>
                                <PersonalNotificationSettingsPage {...props} />
                            </MasterLayout>
                        )}
                    />
                    <NotificationsPanel />
                </Router>
            </LayoutProvider>
        </SecutiryContext.Provider>
    );
};

export default App;
