const GuestWidget = (props) => {
    const { guest_count_today, guest_count_today_car } = props;

    return (
        <div className='card border widget-card mb-6'>
            <div className='card-body p-6'>
                <div className='widget-title mb-3'>
                    <img
                        className='img-fluid me-4'
                        src='/media/icons/avanguard/widgetGuestIcon.png'
                    />
                    Посетители сегодня
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>
                        {guest_count_today}
                    </div>
                    <div className='widget-count-title'>Всего</div>
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>
                        {guest_count_today_car}
                    </div>
                    <div className='widget-count-title'>На авто</div>
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>
                        {(guest_count_today ?? 0) -
                            (guest_count_today_car ?? 0)}
                    </div>
                    <div className='widget-count-title'>Пешеходы</div>
                </div>
            </div>
        </div>
    );
};

export default GuestWidget;
