import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';
import { addNotification } from './NotifySlice';

const nameSlice = 'accountVehicles';
const urlSlice = 'vehicles';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    objectsArray: [],
    pageSize: 50,
    pageSelected: 1,
    paginator: [],
    selectedVehicle: {},
};

export const getVehicleAsync = createAsyncThunk(
    `${nameSlice}/getVehicle`,
    async (data) => {
        const vehicleResult = await Http.get('api/vehicles/' + data);

        return vehicleResult;
    }
);

export const getListAsync = createAsyncThunk(
    `${nameSlice}/getList`,
    async (specs) => {
        const getResult = await Http.get(
            `api/${urlSlice}?accountId=${specs.accountId}&search=${specs.searchString}&pageIndex=${specs.pageIndex}&pageSize=${specs.pageSize}&sort=${specs.sort}&withBlacklist=${specs.withBlacklist}`
        );
        return getResult;
    }
);

export const createAsync = createAsyncThunk(
    `${nameSlice}/create`,
    async (entity, thunkAPI) => {
        const createResult = await Http.post(`api/${urlSlice}`, true, entity);
        if (
            createResult !== undefined &&
            createResult != null &&
            createResult.errorDescription !== undefined
        ) {
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка сохранения!',
                    description: createResult?.errorDescription,
                    type: 'danger',
                })
            );
        }

        return createResult;
    }
);

export const updateAsync = createAsyncThunk(
    `${nameSlice}/update`,
    async (entity, thunkAPI) => {
        const updateeResult = await Http.put(
            `api/${urlSlice}/${entity.id}`,
            true,
            entity
        );
        if (updateeResult !== undefined && updateeResult != null) {
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Транспорт сохранён!',
                    description: 'Данные транспорта успешно сохранены!',
                    type: 'success',
                })
            );
        } else {
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка сохранения!',
                    description:
                        'Не удалось сохранить данные транспорта! Перезагрузите страницу и попробуйте снова!',
                    type: 'danger',
                })
            );
        }

        return updateeResult.result;
    }
);

export const deleteAsync = createAsyncThunk(
    `${nameSlice}/delete`,
    async (id) => {
        await Http.delete(`api/${urlSlice}/${id}`, true);
        return id;
    }
);

export const accountVehiclesSlice = createSlice({
    name: nameSlice,
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        selectPage: (state, action) => {
            state.pageSelected = action.payload;
        },
    },
    extraReducers: {
        [getVehicleAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getVehicleAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.selectedVehicle = action.payload.result;
            }
            state.isLoading = false;
        },
        [getVehicleAsync.rejected]: (state, action) => {
            console.error('getVehicleError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [getListAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getListAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.objectsArray = action.payload.result;
                state.paginator = action.payload.paginator;
            }
            state.isLoading = false;
        },
        [getListAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/getListError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [createAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.objectsArray.push(action.payload.result);
            }
        },
        [createAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/createError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [updateAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.selectedVehicle = action.payload;
                const updateObj = state.objectsArray.map((item) => {
                    if (item.id === action.payload.id) {
                        item = action.payload;
                    }
                    return item;
                });
                state.objectsArray = updateObj;
            }
        },
        [updateAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/updateError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [deleteAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                const updateObj = state.objectsArray.filter(
                    (x) => x.id !== action.payload
                );
                state.objectsArray = updateObj;
            }
        },
        [deleteAsync.rejected]: (state, action) => {
            console.log(nameSlice + '/deleteError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});

export const { setPageSize, selectPage } = accountVehiclesSlice.actions;

export const selectVehicle = (state) => state.accountVehicles.selectedVehicle;
export const selectList = (state) => state.accountVehicles.objectsArray;
export const selectIsLoading = (state) => state.accountVehicles.isLoading;
export const selectPaginator = (state) => state.accountVehicles.paginator;

export default accountVehiclesSlice.reducer;
