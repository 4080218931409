import { useContext, useEffect, useState } from 'react';
import { Http } from '../../../../http';
import { useDispatch } from 'react-redux';
import { updateAsync } from '../../../../app/storeReducers/AdminAccountsSlice';
import SkeletonInput from '../../../../components/skeleton/SkeletonInput';
import SecutiryContext from '../../../../features/securityContext';
import TransportEmergencySelect from './TransportEmergencySelect';

const AccountEditPanel = (props) => {
    const { accountId } = props;

    const dispatch = useDispatch();
    const { context } = useContext(SecutiryContext);

    const [isLoading, setIsLoading] = useState(true);

    const [name, setName] = useState('');
    const [recognitionIgnoreRegion, setRecognitionIgnoreRegion] =
        useState(false);
    const [banGuestParking, setBanGuestParking] = useState(false);
    const [allowAnonymousGuest, setAllowAnonymousGuest] = useState(false);
    const [allowPersonalCardForGuest, setAllowPersonalCardForGuest] =
        useState(false);
    const [singleGuestParking, setSingleGuestParking] = useState(false);
    const [guestCarNumberRequired, setGuestCarNumberRequired] = useState(false);
    const [hosts, setHosts] = useState([]);
    const [hostSelected, setHostSelected] = useState(-1);
    const [host, setHost] = useState('');
    const [guestQrCode, setGuestQrCode] = useState(false);
    const [changeBlock, setChangeBlock] = useState(0);
    const [account, setAccount] = useState({});
    const [canEdit, setCanEdit] = useState(false);
    const [selectedClearCardHour, setSelectedClearCardHour] = useState(-1);
    const [oneDayTicket, setOneDayTicket] = useState(false);
    const [allowedSpecialTransports, setAllowedSpecialTransports] = useState(
        []
    );
    const [preinstallSpecTrans, setPreinstallSpecTrans] = useState([]);
    const [selectedBlockDuration, setSelectedBlockDuration] = useState(0);
    const [carGuestOrderMode, setCarGuestOrderMode] = useState(0);
    const [webhook, setWebhook] = useState('');

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);

            const getResult = await Http.get('api/accounts/' + accountId, true);
            const accountResult = getResult.result;

            console.log('accountResult', accountResult);

            setAccount(accountResult);
            setName(accountResult.name);
            setRecognitionIgnoreRegion(accountResult.recognitionIgnoreRegion);
            setBanGuestParking(accountResult.banGuestParking);
            setAllowAnonymousGuest(accountResult.allowAnonymousGuest);
            setAllowPersonalCardForGuest(
                accountResult.allowPersonalCardForGuest
            );
            const hostsResult = await Http.get('api/hosts', true);
            setHosts(hostsResult.result);
            setHostSelected(accountResult.host.id);
            setHost(accountResult.host?.title);
            setGuestQrCode(accountResult.guestQrCode);
            setSingleGuestParking(accountResult.singleGuestParking);
            setSelectedClearCardHour(accountResult.clearCardHour ?? -1);
            setGuestCarNumberRequired(accountResult.guestCarNumberRequired);
            setOneDayTicket(accountResult.oneDayTicket);
            setPreinstallSpecTrans(accountResult.allowedSpecialTransports);
            setAllowedSpecialTransports(accountResult.allowedSpecialTransports);
            setSelectedBlockDuration(accountResult.blacklistBlockDuration);
            setCarGuestOrderMode(accountResult.carGuestOrderMode);
            setWebhook(accountResult.webhook);
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [accountId, changeBlock]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(
            context?.isRoot ||
                actions?.includes('account_edit') ||
                actions?.includes('subaccount_edit')
        );
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            id: accountId,
            name,
            recognitionIgnoreRegion,
            banGuestParking,
            allowAnonymousGuest,
            allowPersonalCardForGuest,
            guestQrCode,
            clearCardHour: selectedClearCardHour,
            guestCarNumberRequired,
            singleGuestParking,
            oneDayTicket,
            allowedSpecialTransports,
            blacklistBlockDuration: selectedBlockDuration,
            carGuestOrderMode,
            webhook,
        };
        dispatch(updateAsync(entity));
    };

    const blockAccount = async () => {
        const getResult = await Http.put(
            'api/accounts/' + accountId + '/block',
            true
        );
        setChangeBlock(changeBlock + 1);
    };

    const unblockAccount = async () => {
        const getResult = await Http.put(
            'api/accounts/' + accountId + '/unblock',
            true
        );
        setChangeBlock(changeBlock + 1);
    };

    return (
        <div className='card'>
            <div className='card-body py-3'>
                <div className='mb-3'>
                    {account.isBlocked ? (
                        <div className='mb-4'>
                            <span className='badge bg-danger'>Блокировка</span>
                        </div>
                    ) : null}
                    <SkeletonInput
                        isLoading={isLoading}
                        id='name'
                        label='Название'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                {context?.isRoot ? (
                    <div className='mb-5'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='host'
                            label='Хост'
                            value={host}
                            readOnly
                        />
                    </div>
                ) : null}

                <div className='form-group form-check mb-3'>
                    <input
                        type='checkbox'
                        className='form-check-input'
                        id='banGuestParking'
                        checked={banGuestParking}
                        onChange={(e) => setBanGuestParking(e.target.checked)}
                    />
                    <label
                        className='form-check-label'
                        htmlFor='banGuestParking'
                    >
                        Запрет парковки посетителей
                    </label>
                </div>
                <div className='mb-3'>
                    <label className='form-label'>
                        Создание заявки на транспорт охраной
                    </label>
                    <select
                        className='form-select'
                        value={carGuestOrderMode}
                        onChange={(e) => setCarGuestOrderMode(e.target.value)}
                    >
                        <option value='0'>Выключено</option>
                        <option value='1'>Включено</option>
                        <option value='2'>
                            Включено с подтверждением выезда
                        </option>
                        <option value='3'>Включено с быстрой кнопкой</option>
                        <option value='4'>
                            Включено с быстрой кнопкой с подтверждением выезда
                        </option>
                    </select>
                </div>

                {account.type !== 'subaccount' ? (
                    <>
                        <div className='form-group form-check mb-3'>
                            <input
                                type='checkbox'
                                className='form-check-input'
                                id='guestCarNumberRequired'
                                checked={guestCarNumberRequired}
                                onChange={(e) =>
                                    setGuestCarNumberRequired(e.target.checked)
                                }
                            />
                            <label
                                className='form-check-label'
                                htmlFor='guestCarNumberRequired'
                            >
                                Для посетителей обязателен номер автомобиля
                            </label>
                        </div>

                        <div className='form-group form-check mb-3'>
                            <input
                                type='checkbox'
                                className='form-check-input'
                                id='recognitionIgnoreRegion'
                                checked={recognitionIgnoreRegion}
                                onChange={(e) =>
                                    setRecognitionIgnoreRegion(e.target.checked)
                                }
                            />
                            <label
                                className='form-check-label'
                                htmlFor='recognitionIgnoreRegion'
                            >
                                Игнорировать регион при распознавании
                            </label>
                        </div>

                        <div className='form-group form-check mb-3'>
                            <input
                                type='checkbox'
                                className='form-check-input'
                                id='allowAnonymousGuest'
                                checked={allowAnonymousGuest}
                                onChange={(e) =>
                                    setAllowAnonymousGuest(e.target.checked)
                                }
                            />
                            <label
                                className='form-check-label'
                                htmlFor='allowAnonymousGuest'
                            >
                                Разрешить создавать посетителей без номера
                                телефона
                            </label>
                        </div>
                        <div className='form-group form-check mb-3'>
                            <input
                                type='checkbox'
                                className='form-check-input'
                                id='allowPersonalCardForGuest'
                                checked={allowPersonalCardForGuest}
                                onChange={(e) =>
                                    setAllowPersonalCardForGuest(
                                        e.target.checked
                                    )
                                }
                            />
                            <label
                                className='form-check-label'
                                htmlFor='allowPersonalCardForGuest'
                            >
                                Разрешить вход посетителей с персональными
                                картами
                            </label>
                        </div>
                        <div className='form-group form-check mb-3'>
                            <input
                                type='checkbox'
                                className='form-check-input'
                                id='guestQrCode'
                                checked={guestQrCode}
                                onChange={(e) =>
                                    setGuestQrCode(e.target.checked)
                                }
                            />
                            <label
                                className='form-check-label'
                                htmlFor='guestQrCode'
                            >
                                Генерировать QR код у посетителей
                            </label>
                        </div>
                        <div className='form-group form-check mb-3'>
                            <input
                                type='checkbox'
                                className='form-check-input'
                                id='singleGuestParking'
                                checked={singleGuestParking}
                                onChange={(e) =>
                                    setSingleGuestParking(e.target.checked)
                                }
                            />
                            <label
                                className='form-check-label'
                                htmlFor='singleGuestParking'
                            >
                                Однократный въезд для посетителей
                            </label>
                        </div>
                        <div className='form-group form-check mb-3'>
                            <input
                                type='checkbox'
                                className='form-check-input'
                                id='oneDayTicket'
                                checked={oneDayTicket}
                                onChange={(e) =>
                                    setOneDayTicket(e.target.checked)
                                }
                            />
                            <label
                                className='form-check-label'
                                htmlFor='oneDayTicket'
                            >
                                Для посетителей субаккаунтов пропуск на один
                                день
                            </label>
                        </div>
                        <div className='mb-3'>
                            <label className='form-label'>
                                Параметры обнуления пропусков посетителей
                            </label>
                            <select
                                className='form-select'
                                value={selectedClearCardHour}
                                onChange={(e) =>
                                    setSelectedClearCardHour(e.target.value)
                                }
                            >
                                <option value='-1'>нет</option>
                                <option value='0'>00:00</option>
                                <option value='2'>02:00</option>
                                <option value='4'>04:00</option>
                                <option value='6'>06:00</option>
                                <option value='8'>08:00</option>
                            </select>
                        </div>
                        <TransportEmergencySelect
                            accountEmergences={preinstallSpecTrans}
                            setAccountEmergences={(items) =>
                                setAllowedSpecialTransports(items)
                            }
                        />
                        <div className='mb-3'>
                            <label className='form-label'>
                                Поиск кандидатов для черного списка.
                                <br />
                                Укажите число дней возможной блокировки
                            </label>
                            <select
                                className='form-select'
                                value={selectedBlockDuration}
                                onChange={(e) =>
                                    setSelectedBlockDuration(e.target.value)
                                }
                            >
                                <option value='0'>Выключено</option>
                                <option value='7'>7 дней</option>
                                <option value='14'>14 дней</option>
                                <option value='21'>21 день</option>
                                <option value='28'>28 дней</option>
                            </select>
                        </div>
                        <div className='mb-3'>
                            <SkeletonInput
                                isLoading={isLoading}
                                id='webhook'
                                label='Вебхук для отправки событий'
                                value={webhook}
                                onChange={(e) => setWebhook(e.target.value)}
                            />
                        </div>
                    </>
                ) : (
                    ''
                )}
                <div className='row mb-3 mt-5'>
                    <div className='col'>
                        {context?.isRoot ? (
                            <>
                                {account.isBlocked ? (
                                    <>
                                        <button
                                            type='button'
                                            className='btn av-btn-simple me-4'
                                            onClick={unblockAccount}
                                            disabled={!canEdit}
                                        >
                                            Разблокировать
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        type='button'
                                        className='btn av-btn-danger me-4'
                                        onClick={blockAccount}
                                        disabled={!canEdit}
                                    >
                                        Заблокировать
                                    </button>
                                )}
                            </>
                        ) : null}
                    </div>
                    <div className='col text-end'>
                        <button
                            type='button'
                            className='btn av-btn-primary'
                            onClick={saveEntity}
                            disabled={!canEdit}
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountEditPanel;
