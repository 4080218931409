import { useEffect, useState } from 'react';
import FindComponent from '../../../../components/FindComponent';
import Paginator from '../../../../components/Paginator';
import PermissionPage from '../../../../components/PermissionPage';
import SkeletonTableFull from '../../../../components/skeleton/SkeletonTableFull';
import {
    DateToString,
    ToUtcString,
    UtcToLocal,
} from '../../../../helpers/DateHelpers';
import { HumanPhone } from '../../../../helpers/PhoneHelper';
import { Http } from '../../../../http';
import { saveAs } from 'file-saver';
import SkeletonTable from '../../../../components/skeleton/SkeletonTable';
import ThSortable from '../../../../components/table/ThSortable';
import FilterAccountSelector from '../../../accesslog/filters/FilterAccountSelector';

const NightWatchPage = (props) => {
    const accountId = props.match.params.accountId;

    const [isLoading, setIsLoading] = useState(true);
    const [guests, setGuests] = useState([]);
    // Всё, что относится к пагинатору
    const [paginator, setPaginator] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [forcePage, setForcePage] = useState(1);

    const [sort, setSort] = useState('');
    const [sortDest, setSortDest] = useState('asc');
    const [vehSort, setVehSort] = useState('');

    const [filterAccount, setFilterAccount] = useState('')


    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            await loadData();
            setIsLoading(false);
            setForcePage(pageIndex - 1);
        }

        loadResourcesAndDataAsync();
    }, [pageIndex, pageSize, vehSort, filterAccount]);

    const loadData = async () => {
        const getResult = await Http.get(
            `api/guests/auto/control?accountId=${accountId}&pageIndex=${pageIndex}&pageSize=${pageSize}&sort=${vehSort}&subAccountId=${filterAccount}`,
            true
        );
        setGuests(getResult.result);
        setPaginator(getResult.paginator);
    };

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageIndex(1);
        setPageSize(size);
    };

    const resetCar = async (id) => {
        const getResult = await Http.put(`api/guests/${id}/auto/reset`, true);
        await loadData();
    };

    const columnFilter = (colName) => {
        const dest =
            colName === sort ? (sortDest === 'asc' ? 'desc' : 'asc') : 'asc';
        setSort(colName);
        setSortDest(dest);
        setVehSort(colName + (dest === 'desc' ? '_desc' : ''));
    };

    const exportData = async () => {
        const getResult = await Http.getMedia(
            `api/guests/auto/control?accountId=${accountId}&pageIndex=1&pageSize=10000&export=true&subAccountId=${filterAccount}`
        );
        var blob = new Blob([getResult], {
            type: 'text/csv',
        });
        saveAs(blob, 'Ночной дозор.csv');
    };

    return (
        <PermissionPage accountId={accountId} roles={['guest_car_panel']}>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Ночной дозор</h1>
                    <div className='row mb-3'>
                        <div className='col text-start'>
                            <div style={{display: 'inline-block'}}>Выберите аккаунт</div>
                            <div style={{display: 'inline-block', marginLeft: 5, width: 250}}>
                                <FilterAccountSelector
                                    callback={(value) => setFilterAccount(value)}
                                />
                            </div>
                        </div>
                        <div className='col text-end'>
                            <button
                                className='btn av-btn-primary ms-2'
                                onClick={() => exportData()}
                            >
                                Экспорт
                            </button>
                        </div>
                    </div>
                    <div className='card border'>
                        <div className='table-responsive'>
                            <SkeletonTable
                                cols={[
                                    'Аккаунт',
                                    'Посетитель',
                                    'Гос. номер',
                                    'Заезд',
                                    '',
                                ]}
                                rowsCount={15}
                                isLoading={isLoading}
                                classTable='table-striped'
                            >
                                <table className='table mb-0'>
                                    <thead>
                                        <tr className='fw-bold av-text-gray-500 border-bottom'>
                                            <ThSortable
                                                className='border-bottom ps-6'
                                                colName='account'
                                                colTitle='Аккаунт'
                                                colChanged={(name) =>
                                                    columnFilter(name)
                                                }
                                                sortCol={sort}
                                                sortDestinaton={sortDest}
                                            />
                                            <ThSortable
                                                className='border-bottom'
                                                colName='guest'
                                                colTitle='Посетитель'
                                                colChanged={(name) =>
                                                    columnFilter(name)
                                                }
                                                sortCol={sort}
                                                sortDestinaton={sortDest}
                                            />
                                            <ThSortable
                                                className='border-bottom'
                                                colName='number'
                                                colTitle='Гос. номер'
                                                colChanged={(name) =>
                                                    columnFilter(name)
                                                }
                                                sortCol={sort}
                                                sortDestinaton={sortDest}
                                            />
                                            <ThSortable
                                                className='border-bottom'
                                                colName='date'
                                                colTitle='Заезд'
                                                colChanged={(name) =>
                                                    columnFilter(name)
                                                }
                                                sortCol={sort}
                                                sortDestinaton={sortDest}
                                            />
                                            <th className='border-bottom'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {guests?.map((item) => (
                                            <tr
                                                key={item.id}
                                                className='cursor-pointer'
                                            >
                                                <td className='ps-6 av-text-gray-500 align-middle'>
                                                    {item.account != null
                                                        ? item.account.title
                                                        : ''}
                                                </td>
                                                <td className='av-text-gray-500 align-middle'>
                                                    {item.guest?.title}
                                                </td>
                                                <td className='fw-bolder align-middle'>
                                                    {item.carNumber +
                                                        ' ' +
                                                        item.carRegion}
                                                </td>
                                                <td>
                                                    {DateToString(UtcToLocal(item.dateIn), true)}
                                                </td>
                                                <td className='align-middle'>
                                                    <button
                                                        className='btn av-btn-primary btn-sm'
                                                        onClick={() => resetCar(item.guest.id)}
                                                    >
                                                        Удалить
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </SkeletonTable>

                        </div>
                        <div className='card-body py-3 px-5'>
                            <Paginator
                                onPageChange={changePage}
                                onPageSizeChange={changePageSize}
                                pageCount={paginator.totalPages}
                                pageSizeArray={[50, 100, 500]}
                                pageSize={pageSize}
                                countCurrent={guests.length}
                                countTotal={paginator.totalCount}
                                forcePage={forcePage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default NightWatchPage;
